.mobile-menu {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 80%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    animation: fadeInLeft;
    animation-duration: 0.5s;
}

.mobile-menu ul {
    padding: 0px;
}

.mobile-menu li {
    text-decoration: none;
    list-style-type: none;
    padding: 10px 20px;
}

.mobile-menu li.empresa {
    padding: 0px;
}

.mobile-menu .active {
    background: #eee;
}