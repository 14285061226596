.nav-link,
.nav-link i{
  color: rgb(130,166,191) !important;
}

.submenu {
  background: rgb(37,68,89) !important;
}

.nav-top,
.nav-bottom {
  border-bottom: 1px solid #07263b !important;
}

.nav-bottom {
  background: #07263b !important;
}

.profile-picture {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ccc;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-align: center;
  line-height: 34px;
}

.navbar.horizontal-layout .nav-bottom .page-navigation {
  -webkit-justify-content: initial;
  justify-content: initial;
}

.navbar.horizontal-layout .nav-bottom .page-navigation .nav-item {
  margin-right: 20px;
}

.navbar.horizontal-layout .nav-top {
  background: #4B488B;
}

@media (max-width: 767.98px) {
  .navbar.horizontal-layout, 
  .nav-top .navbar-menu-wrapper
  .navbar-nav.navbar-nav-right
  .nav-item.nav-profile .nav-profile-name,
  .navbar-brand.brand-logo {
    display: inline-block !important;
  }
  .navbar.horizontal-layout .nav-top .navbar-brand-wrapper{
    width: 200px;
  }
  label {
    margin-top: 8px !important;
  }
}

@media (min-width: 1400px) {
  .content-wrapper {
    max-width: 1320px;
  }
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 991px) {
  .nav-profile {
    display: none;
  }
}