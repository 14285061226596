.tile-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.animate-once {
    animation-name: flipInY;
    animation-duration: 1s;
    animation-iteration-count: 1
}

.a05s {
    animation-duration: 0.75s;
}

.a15s {
    animation-duration: 1.5s;
}

.tile {
    width: 220px;
    height: 220px;
    background: rgb(73,90,126);
    color: #fff;
    margin: 5px;
    display: inline-block;
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px 5px rgb(53,70,106);
}

.tile span {
    position: relative;
    bottom: 0px;
    display: block;
    margin-top: 10px;
}

.tile i {
    font-size: 80px;
    margin-top: 30px;
}

.tile img {
    height: 110px;
}

.tile:hover {
    background: rgb(53,70,106);
    animation-name: pulse;
    animation-duration: 1s;
}

.tile-info {
    background: #35466A;
    color: #fff;
    font-weight: 700;
    float: right;
    padding-top: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    top: 20px;
}

.tile-info-center {
    position: absolute !important;
    top: 10px;
}

@media only screen and (max-width: 1400px) {
    .tile {
        width: 180px;
        height: 180px;
    }
    .tile i {
        font-size: 80px;
        margin-top: 20px;
    }
    .tile img {
        height: 100px;
    }
    .tile-info {
        top: -10px;
    }
}

@media only screen and (max-width: 400px) {
    .tile-container {
        padding: 30px 0px;
    }
    .tile {
        width: 160px;
        height: 160px;
    }
    .tile i {
        font-size: 50px;
        margin-top: 20px;
    }
    .tile img {
        height: 70px;
    }
    .tile-info {
        top: 0px;
    }
    .dropdown-item {
        top: 10px;
    }
}