*:not(i){
  --font-family: 'Comfortaa', cursive !important;
  font-family: 'ABeeZee', sans-serif !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  text-align: right;
  -moz-appearance: textfield;
}

.table td {
  padding: 5px;
  padding-left: 10px;
}

.light-description {
  color: #777;
}

.table thead th {
  border-bottom: 2px solid #aaa;
}

.table.table-bordered {
  border: 1px solid #aaa;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #aaa;
}

 /* Atendimento */

.badge-background {
  border: 1px solid #A0A0A0;
  border-radius: 2px;
}

.badge-none {
  margin: 4px;
}

.badge-gray {
  border: 1px solid #777;
  color: #000;
  font-weight: 700;
}

.badge-remove {
  margin: 4px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  background: #bbb;
  border: 1px dashed;
}

.badge-xs {
  margin: 2px;
  font-size: 10px;
}

span.link {
  cursor: pointer;
  color: #0000EE;
}

table td {
  border-top: 1px solid #ddd !important;
}

/* botão e menu de opções */
.btn-option {
  background: #818181;
  color: #fff;
  font-size: 22px !important;
  border: none;
  padding: 6px 8px !important;
  border-radius: 50%;
  margin: 2px !important;
}

.btn-option:hover {
  background: #07263B !important;
  border: none;
}

.btn-option:active {
  color: #444;
}

.btn-remove {
  background: #fff;
  color: #aaa;
  font-size: 16px !important;
  border: 1px solid #999;
  padding: 5px !important;
  border-radius: 50%;
  margin: 2px !important;
}

.btn-remove:hover {
  background: #ddd !important;
  border: 1px solid #777;
  color: #444;
}

.btn-remove:active {
  color: #444;
}

/* Menu padrão */
.dropdown-menu {
  background: #254459 !important;
  border-radius: 5px 5px 5px 5px !important;
  border: 0px solid #999 !important;
  box-shadow: 5px 5px 10px 1px #777;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item:hover {
  background: #07263B;
  color: #fff;
}

/* Menu usuário */
.dropdown-menu.navbar-dropdown {
  background: rgb(123,120,186) !important;
  border-radius: 00px 0px 10px 10px !important;
  top: 45px !important;
}

.navbar-dropdown .dropdown-item {
  color: #fff;
}

.navbar-dropdown .dropdown-item:hover {
  background: rgb(103,100,166);
  color: #fff;
}

table.table-layout td {
  border: none !important;
}

.swal-text {
  --background-color: #FEFAE3;
  --padding: 17px;
  --border: 1px solid #F0E1A1;
  display: block;
  --margin: 22px;
  text-align: center;
  color: #61534e;
}

.btn-icons:hover {
  color: #fff;
}

.card {
  background-color: #fff !important;
}

/* icone de status */
.icon-status {
    margin-top: 10px;
    display: inline-block;
    background: #ccc;
    color: #fff;
    font-size: 22px !important;
    border: none;
    padding: 6px 8px !important;
    border-radius: 50%;
    margin: 2px !important;
}

.card {
  border: 1px solid #aaa;;
  --box-shadow: 0px 0px 1px 1px #aaa !important;
}

.input-group .form-control {
  border-width: 1px 0px 0px 1px;
}

.input-group-append.search {
  border: 1px solid #000;
  border-width: 1px 1px 1px 0px;
}

.form-control.search-control {
  border: 1px solid #000;
  border-width: 1px 0px 1px 1px;
  background: #fff;
  font-size: 12px;
  border-radius: 0px;
  padding: 24px 24px;
}

.form-control.search-control:focus {
  background: #fff;
  border-radius: 0px;
}

.modal-content {
  background: #fff;
  border-radius: 0px;
}

.square {
  border-radius: 0px !important;
}

@media (min-width: 876px) {
  .modal-md {
    max-width: 800px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0); 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media only screen and (max-width: 991px) {
  .content-wrapper {
    max-width: 100%;
  }

  .card-body {
    overflow-x: scroll;
  }
}


@media only screen and (max-width: 1000px) {
  .content-wrapper {
    padding: 0px;
  }

  .grid-margin {
    margin-bottom: 0px;
  }

  .card-body {
    padding: 20px 10px 20px 10px !important;
  }

  .modal .modal-dialog {
    margin: 0px;
    padding: 0px;
  }

  .btn-add {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
  }
}

.modal {
  overflow-y: auto !important;
}