.footer-info {
    margin-right: 20px;
}

.footer a {
    color: #0000FF;
}

.footer {
    padding: 15px;
    background: #ddd;
}

html {
    background: #ddd;
}