.page-item {
    margin: 4px;
}

.page-item.previous .page-link,
.page-item.next .page-link {
    border-radius: 8px;
}

.paginate_button.page-item.disabled .page-link {
    background: #fff !important;
    border: 1px solid #ccc !important;
    color: #ccc;
}

.page-link {
    background: #eee;
    color: #000;
    border-radius: 8px;
    font-size: 16px !important;
}

.page-link:focus {
    box-shadow: 0px 0px 0px 0px #000 !important;
    border: 1px solid #000 !important;
}

.page-link:hover{
    background: #ccc !important;
    border: 1px solid #bbb !important;
}

.page-item.active .page-link{
    background: #4B488B !important;
    border: 1px solid #4B488B !important;
}

.page-item.active .page-link:hover{
    background: #4B488B !important;
}

.page-link:focus {
    border: 1px solid #4B488B !important;
}

.dataTables_wrapper tr.odd {
    animation: fadeIn;
    animation-duration: 0.75s;
}

.dataTables_wrapper tr.even {
    animation: fadeIn;
    animation-duration: 1s;
}