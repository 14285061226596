.loader-container {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
}

.bar-loader span {
  margin: 2px;
  background: #ccc;
}
