.table-doc tr, .table-doc td {
    border: 0px !important;
}

.table-doc tr:hover {
    background: #ccc;
}

.table-doc td {
    padding-top: 10px;
}

.doc-container {
    padding: 8px 0px !important;
    border: 1px solid #ccc;
    --padding: 10px;
    background: #eee;
    box-shadow: inset 0px 0px 5px 1px #777;
}

.modal-doc {
    max-width: 95%;
    height: 1vh;
}

.modal-doc.modal-dialog {
    margin-top: 60px !important;
}

.modal-doc .modal-body {
    padding: 0px !important;
}

.modal-doc .modal-header {
    padding: 10px !important;
    background: #009688 !important;
    border-radius: 0px;
    display: flex;
    align-items: center;
}

.modal-doc .modal-title {
    color: white !important;
}

.modal-doc .modal-content {
    border: 1px solid black;
}

.frame-doc {
    width: 100%;
    height: 500px;
    margin-bottom: -7px;
}

.modal-doc .modal-header .close span {
    font-size: 30px !important;
    color: #fff !important;
}

.btn-anexo {
    background: rgb(0,120,106);
    border: 1px solid rgb(0,50,50);
}

.btn-anexo:hover {
    background: rgb(0,100,86);
    border: 1px solid rgb(0,50,50);
}

@media (min-height: 800px) {
    .frame-doc  {
        height: 700px;
    }
}

@media (max-width: 600px) { 
    .modal-content {
        width: 105% !important;
    }
}