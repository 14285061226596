.loader-container {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
}

.bar-loader span {
  margin: 2px;
  background: #ccc;
}

.login-logo-container img {
  margin-bottom: 10px;
  width: 50%;
}

.auth.theme-one .auto-form-wrapper {
  padding-top: 0px;
  box-shadow: none !important;
  background: rgba(0,0,0,0) !important;
  border-radius: 25px !important;
}

.full-page-wrapper {
  background: #fff;
}

.btn-primary {
  background: #4B488B;
  border-color: #4B488B;
}

.btn-primary:hover {
  background: rgb(47, 45, 87);
  border-color: rgb(47, 45, 87);
}

.btn-primary:focus {
  background: rgb(47, 45, 87) !important;
  border-color: rgb(47, 45, 87) !important;
}

.login-logo-container {
  text-align: center;
}

.logo-escritorio {
  margin-top: 60px;
}

.logo-londrisoft {
  height: 40px;
}

.logo-londrisoft img {
  height: 100%;
  width: auto;
}

.footer-text, .forgot-password {
  color: #777 !important;
}

.wave {
  -webkit-animation: fadeIn;
          animation: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  background-image: url("/images/ondas.png");
  width: 100%;
  background-size: cover;
  height: 400px;
  background-position: bottom;
  position: fixed;
  bottom: 0px;
}

.wave.gray {
  background-image: url("/images/onda-cinza.png");
}

@media only screen and (max-width: 768px) {
  .wave {
    height: 100px;
  }
  .logo-escritorio {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .wave {
    height: 210px;
  }
}
@media only screen and (max-width: 1400px) {
  .wave {
    height: 275px;
  }
}

#logo-escritorio {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

#input-login {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

#input-senha {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.version-info {
  position: absolute;
  color: white;
  right: 0px;
  bottom: 0px;
  padding: 10px;
}
*:not(i){
  --font-family: 'Comfortaa', cursive !important;
  font-family: 'ABeeZee', sans-serif !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  text-align: right;
  -moz-appearance: textfield;
}

.table td {
  padding: 5px;
  padding-left: 10px;
}

.light-description {
  color: #777;
}

.table thead th {
  border-bottom: 2px solid #aaa;
}

.table.table-bordered {
  border: 1px solid #aaa;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #aaa;
}

 /* Atendimento */

.badge-background {
  border: 1px solid #A0A0A0;
  border-radius: 2px;
}

.badge-none {
  margin: 4px;
}

.badge-gray {
  border: 1px solid #777;
  color: #000;
  font-weight: 700;
}

.badge-remove {
  margin: 4px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  background: #bbb;
  border: 1px dashed;
}

.badge-xs {
  margin: 2px;
  font-size: 10px;
}

span.link {
  cursor: pointer;
  color: #0000EE;
}

table td {
  border-top: 1px solid #ddd !important;
}

/* botão e menu de opções */
.btn-option {
  background: #818181;
  color: #fff;
  font-size: 22px !important;
  border: none;
  padding: 6px 8px !important;
  border-radius: 50%;
  margin: 2px !important;
}

.btn-option:hover {
  background: #07263B !important;
  border: none;
}

.btn-option:active {
  color: #444;
}

.btn-remove {
  background: #fff;
  color: #aaa;
  font-size: 16px !important;
  border: 1px solid #999;
  padding: 5px !important;
  border-radius: 50%;
  margin: 2px !important;
}

.btn-remove:hover {
  background: #ddd !important;
  border: 1px solid #777;
  color: #444;
}

.btn-remove:active {
  color: #444;
}

/* Menu padrão */
.dropdown-menu {
  background: #254459 !important;
  border-radius: 5px 5px 5px 5px !important;
  border: 0px solid #999 !important;
  box-shadow: 5px 5px 10px 1px #777;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item:hover {
  background: #07263B;
  color: #fff;
}

/* Menu usuário */
.dropdown-menu.navbar-dropdown {
  background: rgb(123,120,186) !important;
  border-radius: 00px 0px 10px 10px !important;
  top: 45px !important;
}

.navbar-dropdown .dropdown-item {
  color: #fff;
}

.navbar-dropdown .dropdown-item:hover {
  background: rgb(103,100,166);
  color: #fff;
}

table.table-layout td {
  border: none !important;
}

.swal-text {
  --background-color: #FEFAE3;
  --padding: 17px;
  --border: 1px solid #F0E1A1;
  display: block;
  --margin: 22px;
  text-align: center;
  color: #61534e;
}

.btn-icons:hover {
  color: #fff;
}

.card {
  background-color: #fff !important;
}

/* icone de status */
.icon-status {
    margin-top: 10px;
    display: inline-block;
    background: #ccc;
    color: #fff;
    font-size: 22px !important;
    border: none;
    padding: 6px 8px !important;
    border-radius: 50%;
    margin: 2px !important;
}

.card {
  border: 1px solid #aaa;;
  --box-shadow: 0px 0px 1px 1px #aaa !important;
}

.input-group .form-control {
  border-width: 1px 0px 0px 1px;
}

.input-group-append.search {
  border: 1px solid #000;
  border-width: 1px 1px 1px 0px;
}

.form-control.search-control {
  border: 1px solid #000;
  border-width: 1px 0px 1px 1px;
  background: #fff;
  font-size: 12px;
  border-radius: 0px;
  padding: 24px 24px;
}

.form-control.search-control:focus {
  background: #fff;
  border-radius: 0px;
}

.modal-content {
  background: #fff;
  border-radius: 0px;
}

.square {
  border-radius: 0px !important;
}

@media (min-width: 876px) {
  .modal-md {
    max-width: 800px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0); 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media only screen and (max-width: 991px) {
  .content-wrapper {
    max-width: 100%;
  }

  .card-body {
    overflow-x: scroll;
  }
}


@media only screen and (max-width: 1000px) {
  .content-wrapper {
    padding: 0px;
  }

  .grid-margin {
    margin-bottom: 0px;
  }

  .card-body {
    padding: 20px 10px 20px 10px !important;
  }

  .modal .modal-dialog {
    margin: 0px;
    padding: 0px;
  }

  .btn-add {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
  }
}

.modal {
  overflow-y: auto !important;
}
.page-item {
    margin: 4px;
}

.page-item.previous .page-link,
.page-item.next .page-link {
    border-radius: 8px;
}

.paginate_button.page-item.disabled .page-link {
    background: #fff !important;
    border: 1px solid #ccc !important;
    color: #ccc;
}

.page-link {
    background: #eee;
    color: #000;
    border-radius: 8px;
    font-size: 16px !important;
}

.page-link:focus {
    box-shadow: 0px 0px 0px 0px #000 !important;
    border: 1px solid #000 !important;
}

.page-link:hover{
    background: #ccc !important;
    border: 1px solid #bbb !important;
}

.page-item.active .page-link{
    background: #4B488B !important;
    border: 1px solid #4B488B !important;
}

.page-item.active .page-link:hover{
    background: #4B488B !important;
}

.page-link:focus {
    border: 1px solid #4B488B !important;
}

.dataTables_wrapper tr.odd {
    -webkit-animation: fadeIn;
            animation: fadeIn;
    -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
}

.dataTables_wrapper tr.even {
    -webkit-animation: fadeIn;
            animation: fadeIn;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}
.nav-link,
.nav-link i{
  color: rgb(130,166,191) !important;
}

.submenu {
  background: rgb(37,68,89) !important;
}

.nav-top,
.nav-bottom {
  border-bottom: 1px solid #07263b !important;
}

.nav-bottom {
  background: #07263b !important;
}

.profile-picture {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ccc;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-align: center;
  line-height: 34px;
}

.navbar.horizontal-layout .nav-bottom .page-navigation {
  justify-content: initial;
}

.navbar.horizontal-layout .nav-bottom .page-navigation .nav-item {
  margin-right: 20px;
}

.navbar.horizontal-layout .nav-top {
  background: #4B488B;
}

@media (max-width: 767.98px) {
  .navbar.horizontal-layout, 
  .nav-top .navbar-menu-wrapper
  .navbar-nav.navbar-nav-right
  .nav-item.nav-profile .nav-profile-name,
  .navbar-brand.brand-logo {
    display: inline-block !important;
  }
  .navbar.horizontal-layout .nav-top .navbar-brand-wrapper{
    width: 200px;
  }
  label {
    margin-top: 8px !important;
  }
}

@media (min-width: 1400px) {
  .content-wrapper {
    max-width: 1320px;
  }
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 991px) {
  .nav-profile {
    display: none;
  }
}
.footer-info {
    margin-right: 20px;
}

.footer a {
    color: #0000FF;
}

.footer {
    padding: 15px;
    background: #ddd;
}

html {
    background: #ddd;
}
.mobile-menu {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 80%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    -webkit-animation: fadeInLeft;
            animation: fadeInLeft;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

.mobile-menu ul {
    padding: 0px;
}

.mobile-menu li {
    text-decoration: none;
    list-style-type: none;
    padding: 10px 20px;
}

.mobile-menu li.empresa {
    padding: 0px;
}

.mobile-menu .active {
    background: #eee;
}
.tile-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.animate-once {
    -webkit-animation-name: flipInY;
            animation-name: flipInY;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1
}

.a05s {
    -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
}

.a15s {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}

.tile {
    width: 220px;
    height: 220px;
    background: rgb(73,90,126);
    color: #fff;
    margin: 5px;
    display: inline-block;
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px 5px rgb(53,70,106);
}

.tile span {
    position: relative;
    bottom: 0px;
    display: block;
    margin-top: 10px;
}

.tile i {
    font-size: 80px;
    margin-top: 30px;
}

.tile img {
    height: 110px;
}

.tile:hover {
    background: rgb(53,70,106);
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}

.tile-info {
    background: #35466A;
    color: #fff;
    font-weight: 700;
    float: right;
    padding-top: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    top: 20px;
}

.tile-info-center {
    position: absolute !important;
    top: 10px;
}

@media only screen and (max-width: 1400px) {
    .tile {
        width: 180px;
        height: 180px;
    }
    .tile i {
        font-size: 80px;
        margin-top: 20px;
    }
    .tile img {
        height: 100px;
    }
    .tile-info {
        top: -10px;
    }
}

@media only screen and (max-width: 400px) {
    .tile-container {
        padding: 30px 0px;
    }
    .tile {
        width: 160px;
        height: 160px;
    }
    .tile i {
        font-size: 50px;
        margin-top: 20px;
    }
    .tile img {
        height: 70px;
    }
    .tile-info {
        top: 0px;
    }
    .dropdown-item {
        top: 10px;
    }
}
.table-doc tr, .table-doc td {
    border: 0px !important;
}

.table-doc tr:hover {
    background: #ccc;
}

.table-doc td {
    padding-top: 10px;
}

.doc-container {
    padding: 8px 0px !important;
    border: 1px solid #ccc;
    --padding: 10px;
    background: #eee;
    box-shadow: inset 0px 0px 5px 1px #777;
}

.modal-doc {
    max-width: 95%;
    height: 1vh;
}

.modal-doc.modal-dialog {
    margin-top: 60px !important;
}

.modal-doc .modal-body {
    padding: 0px !important;
}

.modal-doc .modal-header {
    padding: 10px !important;
    background: #009688 !important;
    border-radius: 0px;
    display: flex;
    align-items: center;
}

.modal-doc .modal-title {
    color: white !important;
}

.modal-doc .modal-content {
    border: 1px solid black;
}

.frame-doc {
    width: 100%;
    height: 500px;
    margin-bottom: -7px;
}

.modal-doc .modal-header .close span {
    font-size: 30px !important;
    color: #fff !important;
}

.btn-anexo {
    background: rgb(0,120,106);
    border: 1px solid rgb(0,50,50);
}

.btn-anexo:hover {
    background: rgb(0,100,86);
    border: 1px solid rgb(0,50,50);
}

@media (min-height: 800px) {
    .frame-doc  {
        height: 700px;
    }
}

@media (max-width: 600px) { 
    .modal-content {
        width: 105% !important;
    }
}
.colaborador-container {
    border: 1px solid #ccc;
    padding: 10px;
    background: #eee;
    box-shadow: inset 0px 0px 5px 1px #777;
}

.colaborador {
    display: inline-block ;
    font-size: 14px;
    cursor: pointer;
    padding: 2px 10px;
    border-radius: 6px;
    background: #4B488B;
    color: white;
    margin: 4px;
    box-shadow: 0px 0px 5px 1px #777;
}

.colaborador:hover {
    background: #777;
    box-shadow: 0px 0px 5px 1px #777;
}

.colaborador-lista {
    border: 1px solid #ccc;
    padding: 10px;
    background: #eee;
    box-shadow: inset 0px 0px 5px 1px #777;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.colaborador-select {
    border: 1px solid #eee;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
}

.colaborador-select:hover {
    background: #ccc;
    color: white;
}

.colaborador-select.selecionado {
    border: 1px solid #eee;
    background: #4B488B;
    color: white;
}

.colaborador-add {
    color: white;
    cursor: pointer;
    padding: 4px 20px;
    border-radius: 6px;
    background: #888;
    margin: 4px;
    box-shadow: 0px 0px 5px 1px #777;
}

.colaborador-add:hover {
    color: white;
    background: #777;
}

.btn-action {
    padding: 10px;
    display: block;
}

.btn-action i {
    margin: 0px;
}
.updated-row {
    -webkit-animation: bounceIn;
            animation: bounceIn;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    background: #eee !important;
}
