.login-logo-container img {
  margin-bottom: 10px;
  width: 50%;
}

.auth.theme-one .auto-form-wrapper {
  padding-top: 0px;
  box-shadow: none !important;
  background: rgba(0,0,0,0) !important;
  border-radius: 25px !important;
}

.full-page-wrapper {
  background: #fff;
}

.btn-primary {
  background: #4B488B;
  border-color: #4B488B;
}

.btn-primary:hover {
  background: rgb(47, 45, 87);
  border-color: rgb(47, 45, 87);
}

.btn-primary:focus {
  background: rgb(47, 45, 87) !important;
  border-color: rgb(47, 45, 87) !important;
}

.login-logo-container {
  text-align: center;
}

.logo-escritorio {
  margin-top: 60px;
}

.logo-londrisoft {
  height: 40px;
}

.logo-londrisoft img {
  height: 100%;
  width: auto;
}

.footer-text, .forgot-password {
  color: #777 !important;
}

.wave {
  animation: fadeIn;
  animation-duration: 1s;
  background-image: url("/images/ondas.png");
  width: 100%;
  background-size: cover;
  height: 400px;
  background-position: bottom;
  position: fixed;
  bottom: 0px;
}

.wave.gray {
  background-image: url("/images/onda-cinza.png");
}

@media only screen and (max-width: 768px) {
  .wave {
    height: 100px;
  }
  .logo-escritorio {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .wave {
    height: 210px;
  }
}
@media only screen and (max-width: 1400px) {
  .wave {
    height: 275px;
  }
}

#logo-escritorio {
  animation-name: bounceInUp;
  animation-duration: 1s;
}

#input-login {
  animation-name: bounceInLeft;
  animation-duration: 1s;
}

#input-senha {
  animation-name: bounceInRight;
  animation-duration: 1s;
}

.version-info {
  position: absolute;
  color: white;
  right: 0px;
  bottom: 0px;
  padding: 10px;
}