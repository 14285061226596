.colaborador-container {
    border: 1px solid #ccc;
    padding: 10px;
    background: #eee;
    box-shadow: inset 0px 0px 5px 1px #777;
}

.colaborador {
    display: inline-block ;
    font-size: 14px;
    cursor: pointer;
    padding: 2px 10px;
    border-radius: 6px;
    background: #4B488B;
    color: white;
    margin: 4px;
    box-shadow: 0px 0px 5px 1px #777;
}

.colaborador:hover {
    background: #777;
    box-shadow: 0px 0px 5px 1px #777;
}

.colaborador-lista {
    border: 1px solid #ccc;
    padding: 10px;
    background: #eee;
    box-shadow: inset 0px 0px 5px 1px #777;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.colaborador-select {
    border: 1px solid #eee;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
}

.colaborador-select:hover {
    background: #ccc;
    color: white;
}

.colaborador-select.selecionado {
    border: 1px solid #eee;
    background: #4B488B;
    color: white;
}

.colaborador-add {
    color: white;
    cursor: pointer;
    padding: 4px 20px;
    border-radius: 6px;
    background: #888;
    margin: 4px;
    box-shadow: 0px 0px 5px 1px #777;
}

.colaborador-add:hover {
    color: white;
    background: #777;
}

.btn-action {
    padding: 10px;
    display: block;
}

.btn-action i {
    margin: 0px;
}